@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.intro {
  padding: 64px 0 80px 0;
  position: relative;
  z-index: 2;
  &__shape {
    position: absolute;
    top: 40px;
    right: -10%;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 60%;
    max-width: 1660px;
    z-index: -1;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
  &__inner {
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    padding: 64px 42px;
    min-height: 640px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      background: #101828;
      img {
        @include fullImage;
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #101828, $alpha: 0.6);
      }
    }
    &-content {
      position: relative;
      text-align: center;
      z-index: 1;
      color: $white;
      h1 {
        margin-bottom: 42px;
      }
      h3 {
        margin-bottom: 10px;
      }
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      button {
        box-sizing: border-box;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        box-shadow: 2px 2px 0px #00d9ca;
        border-radius: 6px;
        font-family: $mainFont;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        color: #008c8c;
        margin-bottom: 40px;
        img {
          width: 16px;
          height: 16px;
          margin-left: 6px;
        }
      }
    }
    &-act {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 1;
      a {
        color: $white;
        font-weight: 16px;
        line-height: 24px;
        display: block;
        margin-top: 10px;
        @include transition;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  h1 {
    br {
      display: none;
    }
    i {
      display: inline-block;
      width: 8px;
      content: "";
      height: 70px;
      background: linear-gradient(180deg, #3ad2c3 15.23%, #2f70e4 100%);
      border-radius: 10px;
      animation: pulsate 0.8s infinite step-start;
    }
    @keyframes pulsate {
      50% {
        opacity: 0;
      }
    }
    span {
      &::after {
        display: none;
      }
    }
    b {
      &::after {
        display: none;
      }
    }
    strong {
      &::after {
        display: none;
      }
    }
  }
}
@include lg-xlg {
  .intro {
    padding: 50px 0 60px;
    &__inner {
      padding: 50px 35px;
    }
  }
}
@include md-lg {
  .intro {
    &__inner {
      &-content {
        button {
          padding: 8px 16px;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 30px;
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}
@include sm-md {
  .intro {
    padding: 30px 0;
    &__inner {
      padding: 35px 20px;
      border-radius: 16px;
      min-height: calc(100vh - 132px);
      h1 {
        margin-bottom: 30px;
      }
    }
  }
}
@include xsm-sm {
  .intro {
    padding: 30px 0;
    h1 {
      br {
        display: block;
      }
    }
    &__inner {
      padding: 30px 20px;
      &-content {
        button {
          padding: 8px 14px;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 24px;
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
