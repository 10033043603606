@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.features {
  position: relative;
  z-index: 1;
  &__shape {
    position: absolute;
    top: 50%;
    @include transformY;
    left: -20%;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 2000px;
    z-index: -1;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
  &__inner {
    display: flex;
    margin: 0 -20px;
  }
  &Item {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    border-radius: 20px;
    cursor: pointer;
    width: calc(33.3% - 40px);
    margin: 0 20px;
    box-sizing: border-box;
    &:hover {
      &::before {
        opacity: 1;
        box-shadow: 0px 0px 24px -4px rgba(102, 112, 133, 0.06),
          0px 1px 4px rgba(234, 236, 240, 0.06);
      }
      .featuresItem {
        &__inner {
          h4 {
            color: $white;
          }
        }
      }
    }
    &__icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-color;
      background: $primary-light;
      margin-bottom: 24px;
      svg {
        width: 48px;
        height: 48px;
      }
    }
    &__inner {
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      height: 100%;
      padding: 32px;
      h4 {
        font-weight: 400;
        @include transition;
      }
    }
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition;
      background: linear-gradient(131.7deg, #03ffe6 1.57%, #37b1cf 53.93%);
    }
  }
}
@include xlg-xxlg {
  .features {
    &__inner {
      margin: 0 -10px;
    }
    &Item {
      width: calc(33.3% - 20px);
      margin: 0 10px;

      &__inner {
        padding: 24px;
      }
    }
  }
}
@include md-lg {
  .features {
    &__inner {
      margin: 0 -7px;
    }
    &Item {
      width: calc(33.3% - 14px);
      margin: 0 7px;
      &__inner {
        padding: 20px;
      }
      &__icon {
        width: 60px;
        height: 60px;
        margin-bottom: 16px;
        svg {
          width: 80%;
          height: 80%;
        }
      }
    }
  }
}
@include sm-md {
  .features {
    &__shape {
      width: 800px;
    }
    &__inner {
      flex-direction: column;
      margin: 0;
    }
    &Item {
      width: 100%;
      margin: 0 0 24px 0;
      &:last-child {
        margin: 0;
      }
      &__inner {
        padding: 30px;
      }
      &__icon {
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
      }
    }
  }
}
