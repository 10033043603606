@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.team {
  padding: 120px 0 160px 0;
  position: relative;
  z-index: 1;
  &__shape {
    position: absolute;
    bottom: 0;
    right: -20%;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 70%;
    max-width: 1800px;
    z-index: -1;
    img {
      width: 100%;
    }
  }
  &__inner {
    h2 {
      text-align: center;
      margin-bottom: 50px;
      position: relative;
      z-index: 1;
    }
  }
  &__row {
    display: flex;
    justify-content: center;
    margin: 0 -30px;
  }
  &Item {
    width: calc(33.3% - 60px);
    margin: 30px;
    &__content {
      &-head {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #d0d5dd;
        a {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: $primary;
          color: $text-color;
          @include transition;
          &:hover {
            opacity: 0.8;
          }
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      &-body {
        padding: 14px 0;
        h6 {
          margin-bottom: 9px;
        }
        ul {
          li {
            color: $text-color;
          }
        }
      }
    }
    &__image {
      padding-bottom: 100%;
      position: relative;
      margin-bottom: 16px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        @include fullImage;
      }
      -webkit-mask-image: url("./mask.svg");
      mask-image: url("./mask.svg");
      -webkit-mask-position: top left;
      mask-position: top left;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
    }
  }
}
@include lg-xlg {
  .team {
    padding: 90px 0 120px;
    &__inner {
      h2 {
        margin-bottom: 35px;
      }
    }
    &__row {
      margin: 0 -15px;
    }
    &Item {
      width: calc(33.3% - 30px);
      margin: 15px;
    }
  }
}
@include md-lg {
  .team {
    padding: 70px 0 80px;
    &Item {
      &__content {
        &-head {
          a {
            width: 30px;
            height: 30px;
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
@include sm-md {
  .team {
    padding: 50px 0;
    &__row {
      flex-wrap: wrap;
    }
    &Item {
      width: calc(50% - 30px);
    }
  }
}
@include xsm-sm {
  .team {
    padding: 35px 0;
    &__inner {
      h2 {
        margin-bottom: 24px;
      }
    }
    &__row {
      flex-direction: column;
      margin: 0;
    }
    &Item {
      width: 100%;
      margin: 0 0 24px 0;
      &:last-child {
        margin: 0;
      }
      &__image {
        margin-bottom: 10px;
      }
      &__content {
        &-head {
          a {
            width: 40px;
            height: 40px;
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
