@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.header {
  background: $primary;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  &__inner {
    position: relative;
    &-logo {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
  }
}
.nav {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform;
  &__inner {
    &-links {
      display: flex;
      align-items: center;
      a {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #016366;
        margin: 0 12px;
        &.active {
          color: $text-color;
          font-weight: 700;
        }
      }
    }
  }
}
.burger {
  display: none;
}
// 1180px
@include lg-xlg {
  .header {
    padding: 16px 0;
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .nav {
    position: fixed;
    top: 72px;
    left: 0;
    width: 100%;
    height: calc(100% - 72px);
    z-index: 9;
    background: rgba($color: #000000, $alpha: 0.4);
    transform: translateX(100%);
    @include transition;
    &.active {
      @include transformReset;
    }
    &__inner {
      flex-direction: column;
      position: absolute;
      justify-content: unset;
      align-items: center;
      box-sizing: border-box;
      top: 0;
      right: 0;
      width: 100%;
      max-width: 340px;
      z-index: 1;
      padding: 50px 20px 20px 20px;
      height: 100%;
      overflow-y: auto;
      background: #03ffe6;
      &-links {
        flex-direction: column;
        a {
          margin: 0 0 30px 0;
          font-size: 24px;
          line-height: 120%;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  .burger {
    display: block;
    cursor: pointer;
    @include transition;
    position: relative;
    width: 30px;
    height: 30px;
    &:hover {
      opacity: 0.8;
    }
    &::before {
      top: 20%;
      width: 100%;
      height: 3px;
      background-color: #101828;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    span {
      top: 50%;
      width: 100%;
      height: 3px;
      background-color: #101828;
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      @include transform;
      @include transition;
    }
    &::after {
      bottom: 20%;
      width: 100%;
      height: 3px;
      background-color: #101828;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    &.active {
      &::before {
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      }
      span {
        opacity: 0;
      }
      &::after {
        bottom: 50%;
        -webkit-transform: translate(-50%, 50%) rotate(-45deg);
        transform: translate(-50%, 50%) rotate(-45deg);
      }
    }
  }
}
