@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  background: $white;
  border-radius: 12px 12px 0 0;
  z-index: 3;
  box-shadow: 0px 4px 10px rgba($color: #000000, $alpha: 0.2);
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-btns {
      display: flex;
      align-items: center;
      button {
        margin-right: 16px;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
      }
    }
    h5 {
    }
  }
}
