@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.footer {
  background: $primary;
  padding: 64px 0 54px 0;
  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &-info {
      display: flex;
      align-items: flex-start;
      &-btns {
        display: flex;
        align-items: center;
        a {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &-logo {
        width: 144px;
        height: 144px;
        display: flex;
        align-items: center;
        margin-right: 24px;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      &-text {
        padding: 16px 0;
        h6 {
          font-weight: 700;
        }
        p {
          margin-bottom: 16px;
        }
        a {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: $text-color;
          color: $white;
          @include transition;
          &:hover {
            opacity: 0.8;
          }
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    &-more {
      text-align: right;
      color: $text-color;
      p {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8px;
      }
      a {
        display: inline-block;
        margin-left: 10px;
        &:first-child {
          margin: 0;
        }
        font-weight: 700;
        color: $text-color;
        @include transition;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
@include sm-md {
  .footer {
    padding: 40px 0;
    &__inner {
      flex-direction: column;

      &-info {
        width: 100%;
        margin-bottom: 24px;
        &-logo {
          width: 110px;
          height: 110px;
          margin-right: 16px;
        }
        &-text {
          padding: 10px 0;
          width: calc(100% - 126px);
          a {
            width: 30px;
            height: 30px;
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      &-more {
        width: 100%;
      }
    }
  }
}
@include xsm-sm {
  .footer {
    padding: 30px 0;
    &__inner {
      &-info {
        flex-direction: column;
        &-logo {
          width: 110px;
          height: 110px;
          margin: 0 0 20px 0;
        }
        &-text {
          padding: 0;
          width: calc(100% - 126px);
          fle a {
            width: 40px;
            height: 40px;
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      &-more {
        width: 100%;
      }
    }
  }
}
