h1 {
  font-weight: 700;
  font-size: 70px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: $white;
  span {
    color: $primary;
  }
}
h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #2a2a2a;
}
h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}
h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
ul {
  list-style: disc;
  padding-left: 16px;
  li {
    list-style: disc;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
@include xlg-xxlg {
  h1 {
    font-size: 70px;
    line-height: 110%;
    i {
      height: 70px !important;
    }
  }
}
@include lg-xlg {
  h1 {
    font-size: 60px;
    i {
      height: 60px !important;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 125%;
  }
  h3 {
    font-size: 28px;
    line-height: 112%;
  }

  h5 {
    font-size: 20px;
    line-height: 133%;
  }
}
@include md-lg {
  h1 {
    font-size: 50px;
    i {
      height: 50px !important;
      width: 5px !important;
    }
  }
  h2 {
    font-size: 35px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
    line-height: 22px;
  }
}
@include sm-md {
  h1 {
    font-size: 40px;
    i {
      height: 40px !important;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 125%;
  }
  h3 {
    font-size: 20px;
  }
}
@include xsm-sm {
  h1 {
    font-size: 30px;
    i {
      height: 30px !important;
    }
  }
  h2 {
    font-size: 25px;
  }
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
}
