@import "./mixin.scss";
@import "./variables.scss";
html,
body {
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($color: #000, $alpha: 0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: #000, $alpha: 0.3);
}
/*** 
====================================================================
Global Settings
====================================================================
   ***/
body {
  font-family: $mainFont;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  &.active {
    overflow: hidden;
  }
}
a {
  text-decoration: none;
  opacity: 1;
  transition: 0.3s ease;
  color: $white;
  cursor: pointer;
}
ul {
  li {
    list-style: none;
  }
}
.auto__container {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
}
.main {
  padding-top: 80px;
  overflow: hidden;
}
.anchor {
  position: relative;
  top: -80px;
}
@include lg-xlg {
  .main {
    padding-top: 72px;
  }
}
@include sm-md {
  .auto__container {
    padding: 0 20px;
  }
}
