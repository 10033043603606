@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.button {
  font-family: $mainFont;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 20px 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  @include transition;
  &:hover {
    opacity: 0.8;
  }
  &.primary {
    color: #101828;
    background: $primary;
  }
}
@include lg-xlg {
  .button {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 18px;
  }
}
@include sm-md {
  .button {
    padding: 8px 16px;
  }
}
