@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.privacy {
  padding: 150px 0;
  box-sizing: border-box;
  min-height: 100vh;
  p {
    margin-bottom: 24px;
    a {
      color: $text-color;
      &:hover {
        color: $primary;
      }
    }
  }
  h3 {
    margin-bottom: 24px;
  }
  ul {
    margin-bottom: 24px;
    li {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
