@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.contact {
  padding: 150px 0;
  box-sizing: border-box;
  min-height: calc(100vh - 80px);
  h3 {
    margin-bottom: 24px;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    h6 {
      width: 300px;
    }
    p {
      a {
        color: $text-color;
      }
      color: #848484;
    }
  }
}
.gray {
  color: #848484;
  padding: 50px 0;
}
